<template>
  <section>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mb-6 ml-6 mr-2 mt-4 ">
          <v-form ref="form" lazy-validation>
            <v-card-title>Modification Sous Theme</v-card-title>
            <v-card-text class="mt-4">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="propheteInfo.name"
                    label="Titre"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <vue-upload-multiple-image
                    v-if="propheteInfo.name"
                    style="margin-left: 160px;"
                    v-model="images"
                    :data-images="images"
                    name="images"
                    drag-text="Veuillez ajoutez une images svp!"
                    browse-text="Browse image"
                    primary-text="Primary image"
                    mark-is-primary-text="Set default image"
                    :min-image="1"
                    :multiple="false"
                    :showEdit="false"
                    popup-text="Description default image"
                    drop-text="Drag and drop"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                  >
                  </vue-upload-multiple-image>
                  <v-progress-linear
                    v-if="showlowding && uploadPercentage < 100"
                    style="max-width: 190px; margin-left: 160px;"
                    color="primary"
                    indeterminate
                    rounded
                    height="15"
                    ><strong
                      >{{ Math.ceil(uploadPercentage) }}%</strong
                    ></v-progress-linear
                  >
                </v-col>
                <v-col cols="auto" style="margin-top: 16px;">
                  <span>Premium:</span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="propheteInfo.isLocked"
                    hide-details
                    inset
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select
                    v-model="propheteInfo.listThemeId[0]"
                    :items="themeArray"
                    clearable
                    label="Theme"
                    item-text="title"
                    item-value="id"
                    outlined
                  ></v-select
                ></v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <div class="d-flex flex-row-reverse">
                    <v-btn
                      :disabled="
                        propheteInfo.name.length <= 0 ||
                          propheteInfo.imgId.length <= 0
                      "
                      class="info"
                      @click="UpdateProphete()"
                    >
                      Suivant
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import axios from "axios";
import Constant from "@/utils/constants";
import { getAllCategory } from "@/views/qorani/services/services";
export default {
  components: { VueUploadMultipleImage },
  data() {
    return {
      fakePercentage: null,
      uploadPercentage: null,
      showlowding: false,
      theme: [],
      themeArray: [],
      images: [],
      propheteInfo: {
        id: "",
        name: "",
        imgUrlId: "",
        listThemeId: [],
      },
      prophete: {
        name: "",
        imgUrlId: "",
      },
      propheteTheme: {
        listThemesId: [],
        propheteId: "",
      },
      imageInfo: {
        fileName: "",
        fileUrl: "",
      },
    };
  },
  async beforeRouteEnter(to, from, next) {
    const PropheteId = to.params.id;
    try {
      const response = await axios.get(
        `${Constant.QORANI}prophete/get/${PropheteId}`
      );
      next((vm) => {
        vm.propheteInfo = response.data;
        vm.images.push({
          name: "",
          path: response.data.imageUrl,
          highlight: 1,
          default: 1,
        });
      });
    } catch (error) {
      console.error("Error fetching product details:", error);
      next(null);
    }
  },
  methods: {
    async UpdateProphete() {
    
    this.propheteTheme.listThemesId[0]= this.propheteInfo.listThemeId[0]
    if (this.propheteTheme.listThemesId[0] === null) {
      this.propheteTheme.listThemesId=[]
    }
    console.log(  this.propheteTheme.listThemesId)
      this.propheteTheme.propheteId = this.propheteInfo.id;
      this.prophete.name = this.propheteInfo.name;
      this.prophete.imgUrlId = this.propheteInfo.imgId;
      this.prophete.isLocked= this.propheteInfo.isLocked;
      const responseProphete = await fetch(
        Constant.QORANI + "prophete/edit/" + this.propheteInfo.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.prophete),
        }
      );

      if (responseProphete.ok) {
        const editListThemes = `${Constant.QORANI}prophete/editListThemes`;

        const responseTheme = await fetch(editListThemes, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.propheteTheme),
        });

        if (!responseTheme.ok) {
          alert(
            "Une erreur est survenue lors de l'association du thème, veuillez réessayer plus tard, Merci."
          );
          return;
        }

        try {
          window.location.reload();
        } catch (error) {
          alert(
            "Une erreur est survenue lors de l'ajout du theme, veuillez réessayez plus tard, Merci."
          );
        }
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`SousTheme/${newImageName}`);
      try {
        this.showlowding = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage = progress;
          },
          (error) => {
            alert(`Error uploading image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            this.imageUrl = url;
            this.imageInfo.fileName = imageName;
            this.imageInfo.fileUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.imageInfo),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.propheteInfo.imgId = responseData.id;
                this.showLoading = false;
              } else {
                this.showLoading = false;
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }

            this.showLoading = false;
          }
        );
        this.showLoading = false;
      } catch (error) {
        this.showlowding = false;
        alert(`Error uploading image ${error.message}`);
      }
    },
    beforeRemove(index, done, fileList) {
      this.showlowding = false;
      this.fakePercentage = 0;
      this.uploadPercentage = 0;
      const r = confirm("remove image");
      if (r === true) {
        const indexInArrayImages = this.images.indexOf(fileList[index].path);
        this.propheteInfo.imageUrl = "";
        this.propheteInfo.imgId = "";
        if (index > -1) {
          this.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
  },
  async created() {
    this.themeArray = await getAllTheme();
  },
};
</script>
